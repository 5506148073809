// extracted by mini-css-extract-plugin
export var accent = "InsiderTips-module--accent--da9be";
export var bottomAccent = "InsiderTips-module--bottom-accent--35960";
export var content = "InsiderTips-module--content--22d93";
export var heading = "InsiderTips-module--heading--d13e0";
export var item = "InsiderTips-module--item--f8c46";
export var items = "InsiderTips-module--items--ee3ce";
export var model = "InsiderTips-module--model--7455a";
export var modelContent = "InsiderTips-module--model-content--6a48b";
export var svg = "InsiderTips-module--svg--01904";
export var wrapper = "InsiderTips-module--wrapper--35f49";