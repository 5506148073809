import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceLaptopAlt from '../LaptopAlt'
import * as cx from './ProductGalleryPhoneTabletAlt.module.scss'

const ProductGalleryPhoneTabletAlt = ({
  wrapperClass = '',
  alt = '',
  mobileSrc = '',
  laptopSrc = '',
  isImageData = false,
  lazy = false,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas
        width={900}
        height={525}
        className={`${cx.placeholder} device-placeholder`}
      ></canvas>
      {isImageData && (
        <>
          <DeviceMobile
            className={`${cx.mobile} mobile`}
            imageFile={mobileSrc}
            imageFileAlt={`${alt} mobile`}
            lazy={lazy}
          />
          <DeviceLaptopAlt
            className={`${cx.laptop} laptop`}
            imageFile={laptopSrc}
            imageFileAlt={`${alt} mobile`}
            lazy={lazy}
          />
        </>
      )}
      {isImageData === false && (
        <>
          <DeviceMobile
            imageSrc={mobileSrc}
            className={`${cx.mobile} mobile`}
            lazy={lazy}
          />
          <DeviceLaptopAlt
            imageSrc={laptopSrc}
            className={`${cx.laptop} laptop`}
            lazy={lazy}
          />
        </>
      )}
    </div>
  )
}

ProductGalleryPhoneTabletAlt.propTypes = {
  wrapperClass: PropTypes.string,
  alt: PropTypes.string,
  mobileSrc: PropTypes.any,
  laptopSrc: PropTypes.any,
  isImageData: PropTypes.bool,
  lazy: PropTypes.bool,
}

export default ProductGalleryPhoneTabletAlt
