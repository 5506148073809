import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import Top50Websites from '@components/global/gallery/Top50Websites'
import LeadContentForm06 from '@components/global/forms/bottom/LeadContentForm06'
import LeadContentForm05 from '@components/global/forms/bottom/LeadContentForm05'

import { modifyImageKeys } from '@utils/modifyImageKeys'

import {
  AwardsLogo,
  StandoutFeatures,
  AwardWinning,
  BuildingWebsite,
  Faqs,
  Reviews,
  InsiderTips,
  PackageBestForYou,
} from '@components/pages/best-re-agent-websites-2022'

import BannerBg from '/content/assets/the-best-real-estate-agent-websites-for-2022/banner-bg.jpg'
import * as cx from './BestReAgentWebsites2022.module.scss'

const BestReAgentWebsites2022 = ({
  data: {
    page,
    bestWebsiteBackground,
    buildingWebsiteBackground,
    buildingWebsiteDevice,
    packageWorksBackground,
  },
  pageContext: { seo, uri },
}) => {
  let top50website = page?.bestREAgentWebsites?.showcase?.items.map(
    (item, key) => {
      let data = {}
      if (item.tablet.length > 0) {
        data = {
          name: item.name,
          location: item.location,
          award: item.awards,
          description: item.description,
          buttonText: 'Get Pricing & Features',
          device: {
            layout: 'feature', // feature
            mobile: item.mobile,
            tablet: item.tablet,
            laptop: item.laptop,
          },
        }
      } else {
        data = {
          name: item.name,
          location: item.location,
          award: item.awards,
          description: item.description,
          buttonText: 'Get Pricing & Features',
          device: {
            layout: 'default',
            mobile: item.mobile,
            laptop: item.laptop,
          },
        }
      }
      return data
    }
  )
  let packages = page?.bestREAgentWebsites?.packages?.items.map((item, key) => {
    return {
      title: item?.name,
      description: item?.description,
      link: item?.button_url,
      tablet: modifyImageKeys(item?.tablet),
      mobile: modifyImageKeys(item?.mobile),
    }
  })

  return (
    <LayoutInnerPage wrapperClassName={cx.wrapper}>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        layout="product-gallery"
        formLayout="default"
        formHeadingClassName="subtitle-6"
        bgImage={BannerBg}
        wrapperClassName={cx.banner}
        breadcrumbs={page?.seo?.breadcrumbs}
        title={page?.bestREAgentWebsites?.banner?.title}
        titleClassName="heading-5"
        description={page?.bestREAgentWebsites?.banner?.description}
        button_text={page?.bestREAgentWebsites?.banner?.formSubmitLabel}
        button_link="/"
        devices={{
          layout: 'PhoneLaptopAltTablet',
          mobile: page?.bestREAgentWebsites?.banner?.mobile,
          tablet: page?.bestREAgentWebsites?.banner?.tablet,
          laptop: page?.bestREAgentWebsites?.banner?.laptop,
        }}
      />
      <AwardsLogo images={page?.bestREAgentWebsites?.awards?.items} />
      <StandoutFeatures
        title={page?.bestREAgentWebsites?.features?.title}
        subtitle={page?.bestREAgentWebsites?.features?.subtitle}
        background={page?.bestREAgentWebsites?.features?.background?.url}
        items={page?.bestREAgentWebsites?.features?.items}
      />
      <Top50Websites
        title={page?.bestREAgentWebsites?.showcase?.title}
        subtitle={page?.bestREAgentWebsites?.showcase?.subtitle}
        itemBackground={extractGatsbyImageDataRelativePath(
          bestWebsiteBackground
        )}
        items={top50website}
      />
      <LeadContentForm06 background="group-1" />
      <AwardWinning
        title={page?.bestREAgentWebsites?.consultation?.title}
        subtitle={page?.bestREAgentWebsites?.consultation?.description}
        buttonLabel={page?.bestREAgentWebsites?.consultation?.button_label}
        buttonUrl={page?.bestREAgentWebsites?.consultation?.button_url}
        wrapperClassName={cx.awardWinning}
        background={page?.bestREAgentWebsites?.consultation?.background}
        model={page?.bestREAgentWebsites?.consultation?.image}
      />
      <BuildingWebsite
        background={getImage(buildingWebsiteBackground)}
        device={getImage(buildingWebsiteDevice)}
      />
      <Faqs
        background={page?.bestREAgentWebsites?.faqs?.background}
        title={page?.bestREAgentWebsites?.faqs?.title}
        faqItems={page?.bestREAgentWebsites?.faqs?.items}
      />
      <Reviews
        title={page?.bestREAgentWebsites?.reviews?.title}
        description={page?.bestREAgentWebsites?.reviews?.description}
        buttonLabel={page?.bestREAgentWebsites?.reviews?.button_label}
        buttonUrl={page?.bestREAgentWebsites?.reviews?.button_url}
        background={page?.bestREAgentWebsites?.reviews?.background}
        gfx={page?.bestREAgentWebsites?.reviews?.image}
      />
      <InsiderTips
        title={page?.bestREAgentWebsites?.tips?.title}
        subtitle={page?.bestREAgentWebsites?.tips?.subtitle}
        description={page?.bestREAgentWebsites?.tips?.description}
        model={page?.bestREAgentWebsites?.tips?.image}
        modelAccent={page?.bestREAgentWebsites?.tips?.top_accent}
        bottomAccent={page?.bestREAgentWebsites?.tips?.bottom_accent}
        tip1_title={page?.bestREAgentWebsites?.tips?.tip1_title}
        tip1_description={page?.bestREAgentWebsites?.tips?.tip1_description}
        tip2_title={page?.bestREAgentWebsites?.tips?.tip2_title}
        tip2_description={page?.bestREAgentWebsites?.tips?.tip2_description}
        tip3_title={page?.bestREAgentWebsites?.tips?.tip3_title}
        tip3_description={page?.bestREAgentWebsites?.tips?.tip3_description}
        tip4_title={page?.bestREAgentWebsites?.tips?.tip4_title}
        tip4_description={page?.bestREAgentWebsites?.tips?.tip4_description}
        tip5_title={page?.bestREAgentWebsites?.tips?.tip5_title}
        tip5_description={page?.bestREAgentWebsites?.tips?.tip5_description}
      />
      <PackageBestForYou
        title={page?.bestREAgentWebsites?.packages?.title}
        subtitle={page?.bestREAgentWebsites?.packages?.subtitle}
        background={getImage(packageWorksBackground)}
        packages={packages}
      />
      <LeadContentForm05 />
    </LayoutInnerPage>
  )
}

export default BestReAgentWebsites2022

export const pageQuery = graphql`
  query BestReAgentWebsites2022PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }
      bestREAgentWebsites {
        banner {
          title
          description
          formTitle
          formSubtitle
          formSubmitLabel
          laptop {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          tablet {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          mobile {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        features {
          title
          subtitle
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            title
            description
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        showcase {
          title
          description
          items {
            display
            name
            url
            location
            description
            awards
            laptop {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        consultation {
          title
          description
          button_label
          button_url
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        faqs {
          title
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            question
            answer
          }
        }
        reviews {
          title
          description
          button_label
          button_url
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        tips {
          title
          subtitle
          description
          top_accent {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          bottom_accent {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          tip1_title
          tip1_description
          tip2_title
          tip2_description
          tip3_title
          tip3_description
          tip4_title
          tip4_description
          tip5_title
          tip5_description
        }
        packages {
          title
          subtitle
          items {
            name
            description
            button_label
            button_url
            tablet {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            mobile {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
          }
        }
        awards {
          items {
            image {
              url
              srcset
              sizes
              width
              height
              alt
              id
            }
          }
        }
      }
    }
    buildingWebsiteBackground: file(
      relativePath: {
        eq: "the-best-real-estate-agent-websites-for-2022/building-new-website-bg.jpg"
      }
    ) {
      id
      ...BestReAgentWebsites2022PageImage
    }
    buildingWebsiteDevice: file(
      relativePath: {
        eq: "the-best-real-estate-agent-websites-for-2022/building-new-website.png"
      }
    ) {
      id
      ...BestReAgentWebsites2022PageImage
    }
    packageWorksBackground: file(
      relativePath: {
        eq: "the-best-real-estate-agent-websites-for-2022/package-bg.jpg"
      }
    ) {
      id
      ...BestReAgentWebsites2022PageImage
    }
  }

  fragment BestReAgentWebsites2022PageImage on File {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
    }
  }
`
