import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import ElementImageFile from '@components/global/element/ImageFile'
import SvgArrowAlt from '@svg/arrowAlt.inline'
import * as cx from './BuildingWebsite.module.scss'

const BuildingWebsite = ({ background, device }) => {
  const [isReadMore, setIsReadMore] = useState(false)
  const [height, setHeight] = useState('0px')

  const contentSpace = useRef(null)

  const toggleAccordion = (e) => {
    setIsReadMore(!isReadMore)
    setHeight(!isReadMore ? `${contentSpace.current.scrollHeight}px` : '0px')
  }

  return (
    <section className={cx.wrapper}>
      <ElementImageFile
        className={cx.background}
        src={background}
        alt="Building Website Background"
        lazy={true}
      />

      <div className={`${cx.content} ${isReadMore ? cx.active : ''}`}>
        <div className={cx.textContent}>
          <h2 className="heading-5">Thinking about building a new website?</h2>
          <p className="default-body">
            The most successful REALTORS in the world partner with an
            experienced web agency for custom website design that will build
            interest for potential clients. There are plenty of ways to quickly
            build a site, just like there are plenty of ways to buy or sell a
            home. However, if you are at the top of your profession or aspire to
            be, you shouldn't be looking for easy, quick, cheap - your choice
            should focus on creating a unique, best-in-class website. We have
            showcased some of the best real estate websites online. You can
            achieve the same level of style and function by hiring professional
            website builders.
          </p>

          <div
            ref={contentSpace}
            style={{ maxHeight: height }}
            className={cx.extraContent}
          >
            <p className="default-body">
              There are over 400 million active websites on the internet today,
              vying for the attention of a global audience that is now 3.5
              billion people strong - that's half the world's population. Since
              most people begin their real estate search online, an attractive,
              well-built website is absolutely vital to your success. It's more
              important than ever that you approach a website build with
              attention and purpose because, over time, it will drive more
              engagement with your brand than any other marketing you do.
            </p>

            <p className="default-body">
              A well-designed website can elevate your brand, especially when
              combined with original content that's optimized to hit top-of-page
              search engine results. It is also essential that the developer
              specializes in building custom websites for real estate agents.
              Think about it in these terms - you wouldn't recommend a client
              work with a REALTOR to buy a home in Manhattan if that agent
              specializes in Seattle.
            </p>
          </div>

          <button
            type="button"
            className={`primary-button alt large ${
              isReadMore ? '' : 'svg-rotate'
            }`}
            onClick={(e) => toggleAccordion(e)}
          >
            <span className="desktop-only">
              {isReadMore ? 'Read Less' : 'Click to Read More'}
            </span>
            <span className="mobile-only">
              {isReadMore ? 'Read Less' : 'Read More'}
            </span>
            <SvgArrowAlt />
          </button>
        </div>
        <ElementImageFile
          className={cx.device}
          src={device}
          alt="Building Website"
          lazy={true}
        />
      </div>
    </section>
  )
}

BuildingWebsite.propTypes = {
  background: PropTypes.object,
  device: PropTypes.object,
  description: PropTypes.string,
}

export default BuildingWebsite
