import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './StandoutFeatures.module.scss'
import { modifyImageKeys } from '@utils/modifyImageKeys'

const StandoutFeatures = ({ title, subtitle, background, items }) => {
  return (
    <section>
      <div
        className={cx.heading}
        style={{ backgroundImage: `url(${background})` }}
      >
        <h2>
          <span className="subtitle-7">{subtitle}</span>
          <span className="heading-3">{title}</span>
        </h2>
      </div>

      <div className={cx.items}>
        {items.map((item, index) => {
          return (
            <div key={index} className={cx.item}>
              <ElementImageFile
                src={modifyImageKeys(item.image)}
                alt={item.title}
                lazy={true}
              />
              <h3 className={`${cx.title} subtitle-2`}>{item.title}</h3>
              <p className={`${cx.description} default-body large`}>
                {item.description}
              </p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

StandoutFeatures.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.string,
  items: PropTypes.array,
}

export default StandoutFeatures
