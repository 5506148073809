import React from 'react'
import PropTypes from 'prop-types'
import { wpautop } from '@utils/wpautop'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import ToggleAccordion from '@components/global/accordions/ToggleAccordion'
import * as cx from './Faqs.module.scss'
import { modifyImageKeys } from '@utils/modifyImageKeys'

const Faqs = ({ background, title, faqItems }) => {
  return (
    <section className={cx.wrapper}>
      <ElementImageFile
        className={cx.background}
        src={modifyImageKeys(background)}
        alt="Frequently Asked Questions"
        lazy={true}
      />

      <div className={cx.content}>
        <h2 className={`subtitle-1 ${cx.heading}`}>{title}</h2>

        {faqItems.map((item, index) => {
          return (
            <ToggleAccordion
              key={index}
              parentClass={cx.accordionParent}
              title={parse(`<span>${item.question}</span>`)}
              btnClass={`${cx.accordionButton} subtitle-3`}
              childClass={cx.accordionContent}
            >
              {parse(wpautop(item.answer, 'default-body'))}
            </ToggleAccordion>
          )
        })}
      </div>
    </section>
  )
}

Faqs.propTypes = {
  background: PropTypes.object,
  title: PropTypes.string,
  faqItems: PropTypes.array,
}

export default Faqs
