import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

// Element
import ElementImageFile from '@components/global/element/ImageFile'
import { modifyImageKeys } from '@utils/modifyImageKeys'

// CSS
import * as cx from './AwardsLogo.module.scss'

const AwardsLogo = ({ images }) => {
  return (
    <div className={cx.wrapper}>
      <Splide
        hasTrack={false}
        tag="div"
        extensions={{ AutoScroll }}
        options={{
          type: 'loop',
          rewind: true,
          arrows: false,
          pagination: false,
          autoHeight: false,
          drag: false,
          perPage: 5,
          autoScroll: {
            speed: 2,
            pauseOnHover: false,
            pauseOnFocus: false,
            rewind: true,
          },
        }}
        className={cx.carousel}
      >
        <SplideTrack>
          {images.map((item, index) => {
            return (
              <SplideSlide key={index}>
                <ElementImageFile
                  src={modifyImageKeys(item.image)}
                  alt={'Awards'}
                  lazy={true}
                />
              </SplideSlide>
            )
          })}
          {/* Clone */}
          {images.map((item, index) => {
            return (
              <SplideSlide key={index}>
                <ElementImageFile
                  src={modifyImageKeys(item.image)}
                  alt={'Awards'}
                  lazy={true}
                />
              </SplideSlide>
            )
          })}
        </SplideTrack>
      </Splide>
    </div>
  )
}

AwardsLogo.propTypes = {
  images: PropTypes.array,
}

export default AwardsLogo
