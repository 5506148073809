import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceTablet from '../Tablet'
import DeviceLaptopAlt from '../LaptopAlt'
import * as cx from './BestWebsiteFeatureDevice.module.scss'

const BestWebsiteFeatureDevice = ({
  wrapperClass = '',
  mobileSrc,
  tabletSrc,
  laptopSrc,
  alt = '',
  lazy = true,
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={646} height={559} className={cx.placeholder}></canvas>
      <DeviceMobile
        className={`${cx.mobile} mobile`}
        imageFile={mobileSrc}
        imageFileAlt={`${alt} mobile`}
        lazy={lazy}
      />
      <DeviceTablet
        className={`${cx.tablet} ipad-landscape`}
        imageFile={tabletSrc}
        imageFileAlt={`${alt} ipad`}
        lazy={lazy}
      />
      <DeviceLaptopAlt
        className={`${cx.laptop} laptop`}
        imageFile={laptopSrc}
        imageFileAlt={`${alt} laptop`}
        lazy={lazy}
      />
    </div>
  )
}

BestWebsiteFeatureDevice.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.object,
  tabletSrc: PropTypes.object,
  laptopSrc: PropTypes.object,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
}

export default BestWebsiteFeatureDevice
