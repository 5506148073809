// extracted by mini-css-extract-plugin
export var badge = "Top50Websites-module--badge--2726a";
export var content = "Top50Websites-module--content--95c32";
export var counter = "Top50Websites-module--counter--7c754";
export var description = "Top50Websites-module--description--07acc";
export var details = "Top50Websites-module--details--acdc2";
export var device = "Top50Websites-module--device--4b5a0";
export var deviceDefault = "Top50Websites-module--device-default--c02bd";
export var heading = "Top50Websites-module--heading--e1348";
export var headingSection = "Top50Websites-module--heading-section--16984";
export var item = "Top50Websites-module--item--aeada";
export var items = "Top50Websites-module--items--d957f";
export var location = "Top50Websites-module--location--663bc";
export var openModal = "Top50Websites-module--open-modal--905f4";
export var readMore = "Top50Websites-module--read-more--a1eca";
export var title = "Top50Websites-module--title--5e00d";
export var wrapper = "Top50Websites-module--wrapper--296be";