import React from 'react'

const SvgReawContent = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 58"
      style={{ enableBackground: 'new 0 0 52 58' }}
      className={className}
      fill="none"
    >
      <path
        d="M31.2845 45.9238H19.7695V46.9813H31.2845V45.9238Z"
        fill="url(#paint0_linear_6217_69826)"
      />
      <path
        d="M31.2845 43.0801H19.7695V44.1376H31.2845V43.0801Z"
        fill="url(#paint1_linear_6217_69826)"
      />
      <path
        d="M32.3216 40.2363H20.1016V41.2938H32.3216V40.2363Z"
        fill="url(#paint2_linear_6217_69826)"
      />
      <path
        d="M28.2292 48.7441H22.8477V49.8016H28.2292V48.7441Z"
        fill="url(#paint3_linear_6217_69826)"
      />
      <path
        d="M32.2722 41.2938H18.7832V40.3303C18.7832 37.0168 17.8432 33.9383 16.0337 31.4238C14.5532 29.3558 13.8012 26.8883 13.8717 24.3268C14.0362 18.3578 18.8302 13.3758 24.7757 12.9998C28.0422 12.7883 31.1442 13.9163 33.4942 16.1488C35.8207 18.3343 37.1602 21.4363 37.1602 24.6558C37.1602 27.0058 36.4787 29.2618 35.1627 31.1888C33.7292 33.3273 32.8127 35.6068 32.4367 37.9803L32.3427 38.4973L31.3087 38.3328L31.4027 37.8158C31.8022 35.2778 32.7892 32.8573 34.3167 30.6013C35.5152 28.8388 36.1262 26.7943 36.1262 24.6558C36.1262 21.6948 34.9277 18.9453 32.7892 16.9243C30.6272 14.9033 27.8072 13.8928 24.8462 14.0808C19.4177 14.4098 15.0702 18.9218 14.9292 24.3503C14.8587 26.6768 15.5402 28.9093 16.9032 30.7893C18.8067 33.4683 19.8407 36.7113 19.8407 40.2363H32.2722V41.2938Z"
        fill="url(#paint4_linear_6217_69826)"
      />
      <path
        d="M23.8824 41.1763L23.8354 40.6593C23.8354 40.5653 23.2009 32.2698 22.9659 28.3453C21.6264 28.3923 19.8874 28.3923 18.9004 27.4523C18.4304 27.0058 18.1719 26.3948 18.1719 25.6428C18.1719 23.8568 19.3469 22.6348 21.0859 22.6348C23.7179 22.6348 23.8589 25.3843 23.9294 27.0058C23.9294 27.0763 23.9294 27.1703 23.9529 27.2643H25.6449V28.3218H24.0234C24.2584 32.2698 24.8694 40.4948 24.8929 40.5888L24.9399 41.1058L23.8824 41.1763ZM21.1094 23.6688C19.9579 23.6688 19.2529 24.4208 19.2529 25.6193C19.2529 26.0658 19.3704 26.4183 19.6524 26.6768C20.3339 27.3113 21.7909 27.3348 22.9189 27.2878C22.9189 27.2173 22.9189 27.1233 22.8954 27.0528C22.8014 25.1493 22.6134 23.6688 21.1094 23.6688Z"
        fill="url(#paint5_linear_6217_69826)"
      />
      <path
        d="M27.1708 41.1763L26.1133 41.1058L26.1603 40.5888C26.1603 40.4948 26.9358 30.2018 27.1003 27.0058C27.1943 25.3608 27.3353 22.6348 29.9438 22.6348C31.6828 22.6348 32.8578 23.8333 32.8578 25.6428C32.8578 27.2643 31.8473 28.2513 30.1788 28.2748H29.4268V27.2173H30.1553C31.2363 27.1938 31.7768 26.6768 31.7768 25.6428C31.7768 24.4443 31.0718 23.6923 29.9203 23.6923C28.5573 23.6923 28.2518 24.8203 28.1343 27.0763C27.9698 30.2958 27.1943 40.5653 27.1943 40.6828L27.1708 41.1763Z"
        fill="url(#paint6_linear_6217_69826)"
      />
      <path
        d="M26.0692 9.42773H25.0117V11.0257H26.0692V9.42773Z"
        fill="url(#paint7_linear_6217_69826)"
      />
      <path
        d="M26.0692 9.42773H25.0117V11.0257H26.0692V9.42773Z"
        fill="url(#paint8_linear_6217_69826)"
      />
      <path
        d="M17.2658 11.9254L16.4102 12.5469L17.3493 13.8398L18.2049 13.2183L17.2658 11.9254Z"
        fill="url(#paint9_linear_6217_69826)"
      />
      <path
        d="M11.6313 19.129L11.3047 20.1348L12.8245 20.6284L13.1512 19.6226L11.6313 19.129Z"
        fill="url(#paint10_linear_6217_69826)"
      />
      <path
        d="M38.2331 27.7931L37.9062 28.7988L39.4259 29.2928L39.7528 28.2871L38.2331 27.7931Z"
        fill="url(#paint11_linear_6217_69826)"
      />
      <path
        d="M12.8205 27.7736L11.3008 28.2676L11.6277 29.2733L13.1474 28.7793L12.8205 27.7736Z"
        fill="url(#paint12_linear_6217_69826)"
      />
      <path
        d="M39.4261 19.1472L37.9062 19.6406L38.2328 20.6464L39.7527 20.153L39.4261 19.1472Z"
        fill="url(#paint13_linear_6217_69826)"
      />
      <path
        d="M33.7907 11.9317L32.8516 13.2246L33.7072 13.8461L34.6463 12.5532L33.7907 11.9317Z"
        fill="url(#paint14_linear_6217_69826)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6217_69826"
          x1="35.3317"
          y1="47.4314"
          x2="34.8303"
          y2="43.4559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6217_69826"
          x1="35.3317"
          y1="44.5876"
          x2="34.8303"
          y2="40.6122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6217_69826"
          x1="36.6165"
          y1="41.7439"
          x2="36.1432"
          y2="37.7614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6217_69826"
          x1="30.1206"
          y1="50.2517"
          x2="29.1046"
          y2="46.4872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6217_69826"
          x1="45.3469"
          y1="53.3456"
          x2="-2.32731"
          y2="24.7928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6217_69826"
          x1="28.2714"
          y1="49.067"
          x2="9.13785"
          y2="43.4518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6217_69826"
          x1="35.2282"
          y1="49.067"
          x2="17.7022"
          y2="44.425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6217_69826"
          x1="26.4409"
          y1="11.7058"
          x2="24.0543"
          y2="10.5558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6217_69826"
          x1="26.4409"
          y1="11.7058"
          x2="24.0543"
          y2="10.5558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6217_69826"
          x1="18.9053"
          y1="13.5501"
          x2="16.2984"
          y2="14.0223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_6217_69826"
          x1="13.9128"
          y1="19.4792"
          x2="12.0818"
          y2="21.3938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_6217_69826"
          x1="40.5144"
          y1="28.1438"
          x2="38.6831"
          y2="30.058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_6217_69826"
          x1="13.8206"
          y1="29.0337"
          x2="11.2253"
          y2="27.5516"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_6217_69826"
          x1="40.4258"
          y1="20.4076"
          x2="37.8309"
          y2="18.9246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_6217_69826"
          x1="35.3405"
          y1="12.3632"
          x2="32.3696"
          y2="12.6898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgReawContent
