import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import ElementImageFile from '@components/global/element/ImageFile'
import MobileBackground from '/content/assets/the-best-real-estate-agent-websites-for-2022/award-winning-mobile-bg.jpg'
import * as cx from './AwardWinning.module.scss'
import { modifyImageKeys } from '@utils/modifyImageKeys'

const AwardWinning = ({
  title,
  subtitle,
  buttonLabel,
  buttonUrl,
  background,
  model,
  wrapperClassName,
}) => {
  return (
    <section
      className={`${cx.wrapper} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${MobileBackground})` }}
    >
      <ElementImageFile
        className={cx.background}
        src={modifyImageKeys(background)}
        alt="Award Winning Background"
        lazy={true}
      />

      <div className={cx.holder}>
        <div className={cx.image}>
          <ElementImageFile
            src={modifyImageKeys(model)}
            alt="Award Winning Model"
            lazy={true}
          />
        </div>
        <div className={cx.content}>
          <h2 className="desktop-only heading-3">{title}</h2>
          <h2 className="mobile-only subtitle-2">{title}</h2>
          <p className="default-body">{subtitle}</p>
          <Link to={`${buttonUrl}`} className="primary-button large">
            {buttonLabel}
          </Link>
        </div>
      </div>
    </section>
  )
}

AwardWinning.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  background: PropTypes.object,
  model: PropTypes.object,
}

export default AwardWinning
