import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { wpautop } from '@utils/wpautop'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

// Component
import BestWebsiteFeatureDevice from '@components/global/devices/BestWebsiteFeatureDevice'
import ProductGalleryPhoneTabletAlt from '@components/global/devices/ProductGalleryPhoneTabletAlt'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'

// CSS
import * as cx from './Top50Websites.module.scss'

// Svg
import SvgArrowAlt from '@svg/arrowAlt.inline'

const Top50Websites = ({
  title = 'Real Estate Agent Websites',
  subtitle = 'The Best of the Best ',
  items,
  itemBackground,
}) => {
  const modalFunc = useRef()
  const [isReadMore, setIsReadMore] = useState(true)

  return (
    <section className={cx.wrapper}>
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Want to Build an <br />Award-Winning Website?"
        mobileTitle="Want to Build <br />An Award-Winning <br />Website?"
        template={'best-websites'}
      >
        <FormSignUp
          submitLabel="Get Started"
          labelClassName="desktop-only"
          leadName={removeElementTags(title)}
          messageLabel="Questions or Comments"
        />
      </ModalForm>

      <div className={cx.headingSection}>
        <h2>
          <span className={`subtitle-1`}>{subtitle}</span>
          <span className={`${cx.title} heading-2`}>{title}</span>
        </h2>
      </div>

      <div className={cx.items}>
        {items.map((item, index) => {
          let count = index + 1
          count = count <= 9 ? `0${count}` : count
          const filteredDescription = removeElementTags(item.description)

          return (
            <div
              key={index}
              className={cx.item}
              style={{
                backgroundImage: `linear-gradient(182.85deg, #FFFFFF 7.11%, rgba(255, 255, 255, 0) 27.85%, rgba(255, 255, 255, 0) 75.87%, #FFFFFF 92.13%), url(${itemBackground})`,
              }}
            >
              <div className={cx.content}>
                <div className={cx.device}>
                  {item.device !== undefined &&
                    item.device.layout === 'default' && (
                      <ProductGalleryPhoneTabletAlt
                        wrapperClass={cx.deviceDefault}
                        alt={removeElementTags(item.name)}
                        mobileSrc={item.device.mobile}
                        laptopSrc={item.device.laptop}
                        isImageData={true}
                        lazy={true}
                      />
                    )}
                  {item.device !== undefined &&
                    item.device.layout === 'feature' && (
                      <BestWebsiteFeatureDevice
                        mobileSrc={item.device.mobile}
                        tabletSrc={item.device.tablet}
                        laptopSrc={item.device.laptop}
                        lazy={false}
                      />
                    )}
                </div>
                <div className={cx.details}>
                  <div className={cx.heading}>
                    <div className={`${cx.counter} overline-heading`}>
                      {count}
                    </div>
                    <h2 className={`${cx.title} heading-4`}>
                      {parse(item.name)}
                    </h2>
                    <div className={`${cx.location} overline-heading`}>
                      {item.location}
                    </div>
                  </div>

                  {item.award !== undefined && (
                    <div className={`${cx.badge} badge`}>{item.award}</div>
                  )}

                  <div className="desktop-only">
                    <p className={`${cx.description} default-body`}>
                      {filteredDescription}
                    </p>
                  </div>

                  <div className="mobile-only">
                    <p className={`${cx.description} default-body`}>
                      {isReadMore
                        ? filteredDescription.slice(0, 354)
                        : filteredDescription}
                    </p>

                    <button
                      type="button"
                      className={`${cx.readMore} primary-button alt ${
                        isReadMore ? 'svg-rotate' : ''
                      }`}
                      onClick={(e) => setIsReadMore(!isReadMore)}
                    >
                      <span>{isReadMore ? 'Read More' : ' Show Less'}</span>{' '}
                      <SvgArrowAlt />
                    </button>
                  </div>

                  <button
                    type="button"
                    className={`${cx.openModal} primary-button`}
                    onClick={(e) => modalFunc.current.openModal()}
                  >
                    {item.buttonText}
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

Top50Websites.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  items: PropTypes.array,
  itemBackground: PropTypes.string,
}

export default Top50Websites
