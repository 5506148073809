import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './Reviews.module.scss'
import { modifyImageKeys } from '@utils/modifyImageKeys'

const Reviews = ({
  title,
  description,
  buttonLabel,
  buttonUrl,
  background,
  gfx,
}) => {
  return (
    <section className={cx.wrapper}>
      <ElementImageFile
        className={cx.background}
        src={modifyImageKeys(background)}
        alt="Reviews Background"
        lazy={true}
      />

      <div className={cx.holder}>
        <div className={cx.gfx}>
          <ElementImageFile
            src={modifyImageKeys(gfx)}
            alt="Reviews GFX"
            lazy={true}
          />
        </div>
        <div className={cx.content}>
          <h2 className="subtitle-2">{parse(title)}</h2>
          {parse(description)}
          <Link to={`${buttonUrl}`} className="primary-button large">
            {buttonLabel}
          </Link>
        </div>
      </div>
    </section>
  )
}

Reviews.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  description: PropTypes.string,
  buttonUrl: PropTypes.string,
  background: PropTypes.object,
  gfx: PropTypes.object,
}

export default Reviews
