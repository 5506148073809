import React from 'react'
import PropTypes from 'prop-types'

import ElementImageFile from '@components/global/element/ImageFile'
import SvgReawContent from '@svg/reawContent.inline'
import SvgReawGuidelines from '@svg/reawGuidelines.inline'
import SvgReawPrepared from '@svg/reawPrepared.inline'
import SvgReawProactive from '@svg/reawProactive.inline'
import SvgReawWork from '@svg/reawWork.inline'
import * as cx from './InsiderTips.module.scss'
import { modifyImageKeys } from '@utils/modifyImageKeys'

const InsiderTips = ({
  title,
  subtitle,
  description,
  model,
  modelAccent,
  bottomAccent,
  tip1_title,
  tip1_description,
  tip2_title,
  tip2_description,
  tip3_title,
  tip3_description,
  tip4_title,
  tip4_description,
  tip5_title,
  tip5_description,
}) => {
  return (
    <section className={cx.wrapper}>
      <div className={cx.heading}>
        <div className={cx.modelContent}>
          <ElementImageFile
            className={cx.accent}
            src={modifyImageKeys(modelAccent)}
            alt="How to Build an Outstanding Website - Accent"
            lazy={true}
          />
          <ElementImageFile
            className={cx.model}
            src={modifyImageKeys(model)}
            alt="How to Build an Outstanding Website"
            lazy={true}
          />
        </div>
        <div className={cx.content}>
          <h2 className="subtitle-7">{subtitle}</h2>
          <h3 className="heading-3">{title}</h3>
          <p className="subtitle-5">{description}</p>
        </div>
      </div>

      <div className={cx.items}>
        <div className={cx.item}>
          <div className={cx.svg}>
            <SvgReawWork />
            <h3 className="subtitle-2">{tip1_title}</h3>
          </div>
          <div className={cx.content}>
            <h3 className="subtitle-3">{tip1_title}</h3>
            <p className="default-body large">{tip1_description}</p>
          </div>
        </div>
        <div className={cx.item}>
          <div className={cx.svg}>
            <SvgReawPrepared />
            <h3 className="subtitle-2">{tip2_title}</h3>
          </div>
          <div className={cx.content}>
            <h3 className="subtitle-3">{tip2_title}</h3>
            <p className="default-body large">{tip2_description}</p>
          </div>
        </div>
        <div className={cx.item}>
          <div className={cx.svg}>
            <SvgReawProactive />
            <h3 className="subtitle-2">{tip3_title}</h3>
          </div>
          <div className={cx.content}>
            <h3 className="subtitle-3">{tip3_title}</h3>
            <p className="default-body large">{tip3_description}</p>
          </div>
        </div>
        <div className={cx.item}>
          <div className={cx.svg}>
            <SvgReawGuidelines />
            <h3 className="subtitle-2">{tip4_title}</h3>
          </div>
          <div className={cx.content}>
            <h3 className="subtitle-3">{tip4_title}</h3>
            <p className="default-body large">{tip4_description}</p>
          </div>
        </div>
        <div className={cx.item}>
          <div className={cx.svg}>
            <SvgReawContent />
            <h3 className="subtitle-2">{tip5_title}</h3>
          </div>
          <div className={cx.content}>
            <h3 className="subtitle-3">{tip5_title}</h3>
            <p className="default-body large">{tip5_description}</p>
          </div>
        </div>
      </div>

      <ElementImageFile
        className={cx.bottomAccent}
        src={modifyImageKeys(bottomAccent)}
        alt="How to Build an Outstanding Website - Accent"
        lazy={true}
      />
    </section>
  )
}

InsiderTips.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  model: PropTypes.object,
  modelAccent: PropTypes.object,
  bottomAccent: PropTypes.object,
  tip1_title: PropTypes.string,
  tip1_description: PropTypes.string,
  tip2_title: PropTypes.string,
  tip2_description: PropTypes.string,
  tip3_title: PropTypes.string,
  tip3_description: PropTypes.string,
  tip4_title: PropTypes.string,
  tip4_description: PropTypes.string,
  tip5_title: PropTypes.string,
  tip5_description: PropTypes.string,
}

export default InsiderTips
