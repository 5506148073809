import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import ElementImageFile from '@components/global/element/ImageFile'
import ProductGalleryPhoneTablet from '@components/global/devices/ProductGalleryPhoneTablet'
import * as cx from './PackageBestForYou.module.scss'

const PackageBestForYou = ({
  title = 'Real Estate Websites for Agents and Brokers',
  subtitle = 'Learn which package works best for you',
  background = null,
  packages,
}) => {
  return (
    <section className={cx.wrapper}>
      {background && (
        <ElementImageFile
          className={cx.background}
          src={background}
          alt="Learn which package works best for you - Background"
          lazy={true}
        />
      )}
      <div className={cx.holder}>
        <div className={cx.heading}>
          <h2 className="subtitle-7">{subtitle}</h2>
          <h3 className="heading-5">{title}</h3>
        </div>

        <div className={cx.items}>
          {packages.map((item, index) => {
            return (
              <div key={index} className={cx.item}>
                <div className={cx.device}>
                  <ProductGalleryPhoneTablet
                    mobileSrc={item.mobile.images.fallback.src}
                    tabletSrc={item.tablet.images.fallback.src}
                  />
                </div>
                <div className={cx.details}>
                  <h4 className="subtitle-2">{item.title}</h4>
                  <p className="body-default large">{item.description}</p>
                  <Link to={item.link} className="primary-button">
                    View Gallery
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={cx.viewMore}>
        <Link to="/choose-a-website/" className="primary-button large alt">
          <p>Click to view comparison chart</p>
        </Link>
      </div>
    </section>
  )
}

PackageBestForYou.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.object,
  packages: PropTypes.array,
}

export default PackageBestForYou
