import React from 'react'

const SvgReawPrepared = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 59"
      style={{ enableBackground: 'new 0 0 52 59' }}
      className={className}
      fill="none"
    >
      <path
        d="M20.5663 18.2609V15.1824C20.5663 14.8769 20.8248 14.6184 21.1303 14.6184H29.8723C30.1778 14.6184 30.4363 14.8769 30.4363 15.1824V16.3574H31.6113V15.1824C31.6113 14.2189 30.8358 13.4434 29.8723 13.4434H21.1303C20.1668 13.4434 19.3913 14.2189 19.3913 15.1824V18.2609H4.44531V46.3434H46.5338V18.2609H20.5663ZM45.3823 45.1684H5.62031V20.5874L12.1063 29.0709H19.3678V27.8959H12.6938L6.23131 19.4359H19.3913H20.5663H45.3823V45.1684Z"
        fill="url(#paint0_linear_6217_69744)"
      />
      <path
        d="M30.1301 31.504V29.201H38.8956L43.3371 23.373L42.3971 22.668L38.3081 28.026H30.1066V25.723H20.8711V31.504H30.1301ZM22.0461 26.8745H28.9551V30.3055H22.0461V26.8745Z"
        fill="url(#paint1_linear_6217_69744)"
      />
      <path
        d="M38.8711 39.8472H12.1281V37.5442H10.9531V41.0222H40.0461V35.2412H38.8711V39.8472Z"
        fill="url(#paint2_linear_6217_69744)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6217_69744"
          x1="61.3265"
          y1="60.3447"
          x2="-1.34003"
          y2="1.97035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6217_69744"
          x1="51.2331"
          y1="35.2643"
          x2="37.1225"
          y2="9.14051"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6217_69744"
          x1="50.2713"
          y1="43.4824"
          x2="44.6639"
          y2="22.9344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDCF5A" />
          <stop offset="0.380208" stopColor="#E8AB27" />
          <stop offset="0.9999" stopColor="#D48802" />
          <stop offset="1" stopColor="#F7A310" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgReawPrepared
