import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import FormFreeConsultation from '../../FreeConsultation'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './LeadContentForm06.module.scss'

const LeadContentForm06 = ({
  title = 'Get in touch with us for a',
  subtitle = 'Free Consultation',
  background = 'britney',
}) => {
  const {
    backgroundMobile,
    backgroundBritney,
    backgroundGroupOne,
  } = useStaticQuery(graphql`
    query LeadContentFormZeroSixImage {
      backgroundMobile: file(
        relativePath: { eq: "form/LCF06/britney-mobile.jpg" }
      ) {
        id
        ...LeadContentFormZeroSixImageOptimize
      }
      backgroundBritney: file(relativePath: { eq: "form/LCF06/britney.jpg" }) {
        id
        ...LeadContentFormZeroSixImageOptimize
      }
      backgroundGroupOne: file(relativePath: { eq: "form/LCF06/group-1.jpg" }) {
        id
        ...LeadContentFormZeroSixImageOptimize
      }
    }

    fragment LeadContentFormZeroSixImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          quality: 90
          formats: [AUTO]
          width: 1440
          height: 800
        )
      }
    }
  `)

  const backgroundOverlay = `linear-gradient(79.23deg, rgba(96, 84, 65, 0.8) -13.74%, rgba(35, 30, 23, 0.8) 2.97%, rgba(32, 29, 27, 0.8) 18.26%, rgba(0, 0, 0, 0) 29.79%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`

  let backgroundPhoto = backgroundBritney

  if (background === 'group-1') {
    backgroundPhoto = backgroundGroupOne
  }

  return (
    <section className={cx.wrapper}>
      <div
        className={`${cx.overlay} lfc06-overlay`}
        style={{ backgroundImage: backgroundOverlay }}
      ></div>
      <ElementImageFile
        className={`${cx.wrapperBg} lfc06-wrapper-bg`}
        src={getImage(backgroundPhoto)}
        alt={title}
        width={1440}
        height={800}
        lazy={true}
      />
      <div className={`${cx.formContainer} lfc06-container`}>
        <h2>
          <span className="subtitle-7">{title}</span>
          <span className="heading-5">{subtitle}</span>
        </h2>

        <div className={`${cx.form} lfc06-form`}>
          <FormFreeConsultation
            submitLabel="I want a stunning website!"
            leadName="Bottom FSU"
            isEmailPhoneMerger={true}
            isMessageTextarea={true}
          />
        </div>
      </div>
    </section>
  )
}

LeadContentForm06.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  background: PropTypes.oneOf([
    'britney',
    'britney-2',
    'chad',
    'group-1',
    'group-2',
    'group-3',
    'group-4',
  ]),
}

export default LeadContentForm06
